// src/context/NewsletterContext.js
import React, { createContext, useContext, useState } from "react"

const NewsletterContext = createContext()

export const useNewsletter = () => useContext(NewsletterContext)

export const NewsletterProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const openNewsletter = () => setIsOpen(true)
  const closeNewsletter = () => setIsOpen(false)

  return (
    <NewsletterContext.Provider
      value={{ isOpen, openNewsletter, closeNewsletter }}
    >
      {children}
    </NewsletterContext.Provider>
  )
}
